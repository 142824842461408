/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
// import React, { useContext, useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import qs from 'query-string';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import ContainerWithLinks from './ContainerWithLinks';
import DistrictMemberItem from './DistrictMemberItem';
import MembersList from './MembersList';

import {
  DistrictMember,
  DORoles as DORolesType,
  MEMBER_FILTERS,
} from '@domain/districts';

// import Filters from '@presenters/web/components/DistrictMembersFilters';
import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import { DynamicData } from '@domui-domain/type';
import { useFetchMembersWithFilters } from '@domui-repositories/districts/hooks/useFetchMembersWithFilters';
import {
  getMembershipStatusOptions,
  // isRotaractLeadership,
  useRoleOptions,
} from '@domui-use-cases/clubs';
import {
  buildAGsPageUrl,
  buildOfficersPageUrl,
  // useSetContextManagedRYs,
  //   DistrictContext,
  //   get3yrTermRoles,
  //   getAssignedFuture3yrTermRoleIds,
  //   getClubIdFilterOptions,
  //   getClubTypesOptions,
  //   getDOUnassignedRoles,
  //   getFormattedRoles,
  //   getMemberTypesOptions,
  // isRoleAssignedInFutureRY,
  //   reformatRolesToOptions,
  //   useMembersFilters,
} from '@domui-use-cases/districts';

// import {
//   useFetchAllDistrictClubs,
//   useFetchAllDistrictOfficers,
//   useFetchDistrictOfficersData,
//   useFetchLeadershipRoles,
//   useFetchMembersWithFilters,
// } from '@repositories/districts';

// import { getRotaryYear } from '@utils/datetime';

import { useDistrictMembersFilters } from '@domui-use-cases/districts/hooks/useDistrictMembersFilters';

// import { isRoleAssignedInFutureRY } from 'src/domui/use-cases/districts/helpers/officerTermHelpers';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/graphql';
import { Role } from '@typings/resolvers';

interface Props extends RouteComponentProps {
  districtId: string;
  isRotaractClub?: boolean;
  // riDistrictId?: number | null;
  pageSize?: number;
}
const PAGE_SIZE = 10;

const getMemberItem = (
  DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[],
  nonGovernorsCurrent: DORolesType,
  nonGovernorsNext: DORolesType,
  districtId: string,
  assignedFuture3yrTermRoleIds: string[],
  isRoleFilterApplied?: boolean,
  rolesSelected?: string[]
) => (member: DistrictMember) => {
  const activeMemberships = member?.activeMemberships;
  const associatedClubsInfo = member?.associatedClubsInfo;

  const filteredActiveMemberships = activeMemberships.filter(
    ({ clubId }: { clubId: string }) =>
      associatedClubsInfo.find(
        (club: { clubId: string }) => club.clubId === clubId
      )
  );
  // const getIsAssigned3yrTermRoleInFutureRY = ({
  //   thisDistrictLeadership,
  // }: DistrictMember) =>
  //   thisDistrictLeadership.length === 1 &&
  //   isRoleAssignedInFutureRY(
  //     assignedFuture3yrTermRoleIds,
  //     thisDistrictLeadership[0].roleId
  //   );

  const haveMemberships = {
    honorary: !!filteredActiveMemberships.find(
      ({ type }: { type: string }) =>
        type === MembershipType.Honorary ||
        type === MembershipType.RotaractHonorary
    ),
    notHonorary: !!filteredActiveMemberships.find(
      ({ type }: { type: string }) =>
        type === MembershipType.Member || type === MembershipType.Rotaractor
    ),
  };

  return (
    <DistrictMemberItem
      // roleSelected={rolesSelected}
      isRoleFilterApplied={isRoleFilterApplied}
      member={member}
      //  DORoles={DORoles.filter(role => role.isOfficer)}
      //  areRolesToAssignPresent={
      //   nonGovernorsCurrent.length > 0 || nonGovernorsNext.length > 0
      //  }
      districtId={districtId}
      //  isAssignedInFutureRY={getIsAssigned3yrTermRoleInFutureRY(member)}
      haveMemberships={haveMemberships}
    />
  );
};
const processMembers = (members: DynamicData) => {
  return members?.map(
    (member: {
      firstName: string | null;
      lastName: string | null;
      primaryEmail?: { isPrimary: boolean; email: string } | null;
      primaryPhone?: { isPrimary: boolean; phone: string } | null;
      membership?: Array<{
        id: string | null;
        clubId: string | null;
        clubName: string | null;
        riClubId: string | null;
        clubType: string | null;
        districtId: string | null;
        riDistrictId: string | null;
        admissionDate: string | null;
        terminationDate: string | null;
        state: string | null;
        country: string | null;
        type: string | null;
      }>;
    }) => ({
      ...member,
      nameWithPrefixSuffix: `${member.firstName ?? ''} ${member.lastName ??
        ''}`,
      email: member.primaryEmail?.isPrimary ? member.primaryEmail.email : null,
      phoneNumber: member.primaryPhone?.isPrimary
        ? member.primaryPhone.phone
        : null,
      associatedClubsInfo:
        member.membership?.map(club => ({
          __typename: 'Club',
          clubId: club?.clubId ?? '',
          clubName: club?.clubName ?? '',
          clubType: (club?.clubType ?? '').replace(/ /g, '_'),
          physicalLocation: {
            __typename: 'BaseAddress',
            country: club?.country ?? '',
            state: club?.state ?? '',
            city: null,
            internationalProvince: null,
          },
        })) ?? [],
      activeMemberships:
        member.membership?.map(club => ({
          type: club?.type ?? '',
          clubName: club?.clubName ?? '',
          clubId: club?.clubId ?? '',
          admissionDate: club?.admissionDate ?? '',
          terminationDate: club?.terminationDate ?? '',
        })) ?? [],
    })
  );
};

const DistrictMembers: React.FC<Props> = ({
  districtId,
  location,
  isRotaractClub = false,
  // riDistrictId,
  pageSize = PAGE_SIZE,
}) => {
  // const [context] = useContext(DistrictContext);

  // const {
  //   committeeManager: { isManager },
  //   selectedInfo: { term },
  //   termsToAssign,
  // } = context;
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  // const memberTypesOptions = getMemberTypesOptions(t);

  // const {
  //   searchValues,
  //   filterValues,
  //   currentPage,
  //   handleSearch,
  //   handleApplyFilters,
  //   handleResetFilters,
  //   handlePagination,
  // } = useMembersFilters(memberTypesOptions);

  // const isRoleFilterApplied = Boolean(filterValues.districtRoles.length > 0);
  // const rolesSelected = filterValues.districtRoles.map(({ value }) => value);

  // const [
  //   fetchAllDistrictClubs,
  //   { data: districtClubsData, loading: districtClubsLoading },
  // ] = useFetchAllDistrictClubs();

  // const [
  //   getFuture3yrTermDistrictOfficers,
  //   { data: future3yrTermOfficersData, loading: future3yrTermOfficersLoading },
  // ] = useFetchAllDistrictOfficers();

  // useSetContextManagedRYs(districtId, riDistrictId);

  // const isManagerTwoYears = isManager.currentTerm && isManager.nextTerm;
  // const isElect = !isManager.currentTerm && isManager.nextTerm;

  // const officersData = {
  //   // if user is current or future District Manager (isElect),
  //   // we will have officers data for particular year  in `selectedTerm`,
  //   // since `term?.endDate` in this case is current or next year`s date, accordingly
  //   selectedTerm: useFetchDistrictOfficersData(
  //     districtId,
  //     riDistrictId,
  //     term?.endDate
  //   ),
  //   nextTerm: useFetchDistrictOfficersData(
  //     districtId,
  //     riDistrictId,
  //     isManagerTwoYears ? termsToAssign[1].endDate : ''
  //   ),
  // };

  // Fetch leadership roles
  // const {
  //   leadershipRolesData,
  //   leadershipNextYearRolesData,
  //   districtRoles,
  //   leadershipRolesLoading,
  // } = useFetchLeadershipRoles(
  //   districtId,
  //   isManager,
  //   isManagerTwoYears,
  //   term,
  //   termsToAssign
  // );

  // Fetch active clubs affiliated with the district
  // Values used in the filter by club name
  // useEffect(() => {
  //   if (districtId) {
  //     fetchAllDistrictClubs({
  //       variables: {
  //         districtId,
  //         status: 'Active',
  //       },
  //     });
  //   }
  // }, [fetchAllDistrictClubs, districtId]);

  // Fetch members according to selected filters
  // const { members, membersLoading } = useFetchMembersWithFilters(
  //   pageSize,
  //   currentPage,
  //   searchValues,
  //   filterValues,
  //   districtId,
  //   riDistrictId
  // );
  const canAddMembers = false; // isEditLevel(operationsAccess.memberships);

  const membershipStatusOptions = getMembershipStatusOptions(
    t,
    canAddMembers,
    isRotaractClub
  );

  const { options: roleOptions } = useRoleOptions(districtId, false);

  const multiSelectOptions = {
    roles: roleOptions,
    memberTypes: membershipStatusOptions,
  };
  const allMembershipStatuses = getMembershipStatusOptions(
    t,
    true,
    isRotaractClub
  );

  const { membershipStatus } = location
    ? qs.parse(location.search)
    : { membershipStatus: null };

  const membershipStatusOption =
    (membershipStatus &&
      allMembershipStatuses.filter(
        option => option.value === membershipStatus
      )) ||
    [];

  const {
    selectedValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useDistrictMembersFilters(districtId, membershipStatusOption);

  const {
    districtMembersSearchData,
    loading,
    filters,
  } = useFetchMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    selectedValues
  );
  if (loading) {
    return <Loading />;
  }

  if (
    !districtMembersSearchData ||
    !districtMembersSearchData?.districtMembersDetails
  ) {
    return null;
  }

  const handlePagination = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  // useEffect(() => {
  //   const threeYrTermRoles = get3yrTermRoles(
  //     getFormattedRoles(leadershipRolesData)
  //   );
  //   if (threeYrTermRoles.length && riDistrictId) {
  //     getFuture3yrTermDistrictOfficers({
  //       variables: {
  //         districtId,
  //         riDistrictId,
  //         rotaryYear: String(Number(getRotaryYear()) + 1),
  //         districtRoles: threeYrTermRoles,
  //       },
  //     });
  //   }
  // }, [
  //   districtId,
  //   getFuture3yrTermDistrictOfficers,
  //   leadershipRolesData,
  //   riDistrictId,
  // ]);

  /** Clears isNextStepVisited in localStorage in case of hitting Members tab
   * inside any flow (Assign, Replace, Remove)
   * */
  // useEffect(() => {
  //   window.localStorage.removeItem('isNextStepVisited');
  // }, []);

  // if (
  //   membersLoading ||
  //   districtClubsLoading ||
  //   leadershipRolesLoading ||
  //   officersData.selectedTerm.officersLoading ||
  //   officersData.nextTerm.officersLoading ||
  //   term === null ||
  //   future3yrTermOfficersLoading
  // ) {
  //   return <Loading />;
  // }

  // const clubs = districtClubsData?.districtClubs?.clubs || [];
  // const clubOptionsWithId = getClubIdFilterOptions(clubs, t);

  // const multiSelectOptions = {
  //   memberTypes: memberTypesOptions,
  //   clubTypes: getClubTypesOptions(t),
  //   clubNames: [],
  //   districtRoles: reformatRolesToOptions(districtRoles?.roles || []),
  //   clubIds: clubOptionsWithId,
  // };

  // const DORoles = [
  //   ...(leadershipRolesData?.roles || []),
  //   ...(leadershipNextYearRolesData?.roles || []),
  // ];

  // const [, nonGovernorsCurrent] = getDOUnassignedRoles({
  //   roles: getFormattedRoles({ roles: leadershipRolesData?.roles || [] }),
  //   officers: officersData.selectedTerm.officersData,
  //   year: term?.endDate,
  // });

  // const [, nonGovernorsNext] =
  //   isManagerTwoYears || isElect
  //     ? getDOUnassignedRoles({
  //         roles: getFormattedRoles({
  //           roles: leadershipNextYearRolesData?.roles || [],
  //         }),
  //         ...(isElect
  //           ? {
  //               officers: [...officersData.selectedTerm.officersData],
  //               year: term.endDate,
  //             }
  //           : {
  //               officers: [...officersData.nextTerm.officersData],
  //               year: termsToAssign[1].endDate,
  //             }),
  //       })
  //     : [[], []];

  // const assignedFuture3yrTermRoleIds = getAssignedFuture3yrTermRoleIds(
  //   future3yrTermOfficersData,
  //   term
  // );

  const totalCount = districtMembersSearchData?.totalCount || 0;
  const total = `(${totalCount})`;

  // added for running application for now will be removed later
  const DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[] = [];
  const nonGovernorsCurrent: DORolesType = [];
  const nonGovernorsNext: DORolesType = [];
  const assignedFuture3yrTermRoleIds: string[] = [];
  const isRoleFilterApplied = false;
  const rolesSelected: string[] | undefined = [];

  const members = districtMembersSearchData.districtMembersDetails;

  const processedMembers = processMembers(members);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-members',
          '{{prefix}} District members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <ContainerWithLinks
        isDistrictOfficer
        isAssistantGovernor
        assistantGovernorsUrl={buildAGsPageUrl(districtId)}
        officersUrl={buildOfficersPageUrl(districtId)}
      />
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
      />
      <MembersList
        pageSize={pageSize}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={districtMembersSearchData.totalCount}
        members={processedMembers || []}
        tableTitle={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('district-management.district-members', 'District members')}{' '}
            {total}
          </h2>
        }
        // filters={
        //   <Filters
        //     {...{
        //       multiSelectOptions: { ...multiSelectOptions },
        //       initialFilterValues: { ...filterValues },
        //       applyFilter: handleApplyFilters,
        //       resetFilter: handleResetFilters,
        //       filtersListToShow: [
        //         MEMBER_FILTERS.CLUB_IDS,
        //         MEMBER_FILTERS.MEMBER_TYPES,
        //         MEMBER_FILTERS.DISTRICT_ROLES,
        //       ],
        //     }}
        //   />
        // }
        memberItem={getMemberItem(
          DORoles,
          nonGovernorsCurrent,
          nonGovernorsNext,
          districtId,
          assignedFuture3yrTermRoleIds,
          isRoleFilterApplied,
          rolesSelected
        )}
        showFiltersInContextMenu
        contextMenuTitle={t(
          'district.filters.members',
          'Filter District members'
        )}
        areFiltersEmpty={!total}
      />
    </>
  );
};

export default DistrictMembers;
