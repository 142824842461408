import React from 'react';

import { maxBy, range } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import DetailsChartXAxisTick from './DetailsChartXAxisTick';
import DetailsChartYAxisTick from './DetailsChartYAxisTick';

import { getRoundedMaxValue } from '@use-cases/districts';

import {
  DetailsChartData,
  districtDetailsChartColors,
  GetDetailsChartLinkName,
} from '@domui-domain/districts';
import tailwind from '@styles/tailwind.js';

const graphXTickColor = tailwind.theme.colors.gray['200'];
const graphMainAxisColor = tailwind.theme.colors.gray['600'];

type Props = {
  chartData: DetailsChartData[];
  getLinkHref: (value: string) => string;
  getLinkName: GetDetailsChartLinkName;
  getLinkClass?: (value: string) => string;
  graphWidth?: string;
  graphHeight?: number;
  graphBarWidth?: number;
};

const getGraphColor = (type: string): string => {
  const currentBarName = districtDetailsChartColors.find(
    ({ barName }) => barName === type
  );
  return currentBarName?.color || districtDetailsChartColors[0].color;
};

const DetailsChart: React.FC<Props> = ({
  chartData,
  getLinkHref,
  getLinkName,
  getLinkClass = () => '',
  graphWidth = '100%',
  graphHeight = 130,
  graphBarWidth = 150,
}) => {
  const chartDataMaxTotalCount = maxBy(chartData, 'count');
  const chartDataMaxValue = chartDataMaxTotalCount?.count || 0;

  const XAxisMaxValue = getRoundedMaxValue(chartDataMaxValue);
  const increment = XAxisMaxValue / 4;

  const arrayOfTicks = range(0, XAxisMaxValue + increment, increment);

  return (
    <ResponsiveContainer width={graphWidth} height={graphHeight}>
      <BarChart
        data={chartData}
        barSize={30}
        layout="vertical"
        margin={{ top: 8, right: 16, bottom: 8, left: -16 }}
      >
        <XAxis
          type="number"
          tick={<DetailsChartXAxisTick />}
          domain={[0, XAxisMaxValue]}
          ticks={arrayOfTicks}
          tickLine={{ stroke: graphXTickColor }}
          axisLine={{ stroke: graphMainAxisColor }}
        />
        <Bar dataKey="count">
          {chartData.map(({ name }) => (
            <Cell key={name} fill={getGraphColor(name)} />
          ))}
        </Bar>
        <CartesianGrid horizontal={false} stroke={graphXTickColor} />
        <YAxis
          width={graphBarWidth}
          type="category"
          dataKey="name"
          tickLine={false}
          axisLine={{ stroke: graphMainAxisColor }}
          tick={
            <DetailsChartYAxisTick
              getLinkHref={getLinkHref}
              getLinkName={getLinkName}
              getLinkClass={getLinkClass}
            />
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DetailsChart;
