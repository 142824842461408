/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
// import { has, isEmpty, isEqual, isString, pick, values } from 'lodash';

import TextField from '@components/Formik/PasswordField';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import FormButtons from '@presenters/web/components/FormButtons';

import {
  createEmailWithID,
  createFilledItem,
  createWebsiteWithID,
  ensureArray,
  fillMissingValues,
  processContactInfoForDomino,
  removeInvalidEmails,
  removeInvalidWebsites,
} from './utils';
import {
  checkAddressFieldsRequired,
  editDistrictContactValidationSchema,
} from './validation';

import { useErrorHandling } from '@use-cases/notifications';
// import { getStorageID } from '@use-cases/storage';

import { localizedNavigate } from '@utils/localized-navigate';

import AddressFields from '@domui-components/Formik/Address';
import PhoneInput from '@domui-components/Formik/PhoneInput';
import { FormPhoneItem } from '@domui-domain/clubs/types';
import mapDistrictDetailsToForm from '@domui-domain/districts/mappers/mapDistrictDetailsToForm';
import { DynamicData } from '@domui-domain/type';
import { useUpdateDistrictDetails } from '@domui-hooks/useUpdateDistrictDetails';
import Metadata from '@domui-utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  districtId: string;
  districtData: DynamicData;
  districtDataLoading: boolean;
  setUpdateFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditDistrictContact: React.FC<Props> = ({
  districtId,
  districtData,
  districtDataLoading,
  setUpdateFlag,
}) => {
  const { t } = useTranslation();
  const [countryData, setCountryData] = useState([]);

  setUpdateFlag(false);
  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryData(response?.countries);
    }
    fetchCountryData();
  }, []);

  const {
    data: newData,
    loading: updatedLoading,
    error: updatedError,
    updateDistrictDetails,
  } = useUpdateDistrictDetails();

  useErrorHandling(
    updatedError?.description
      ? updatedError.description
      : t('update-contactInfo.error', 'An error occurred.'),
    !!updatedError,
    'update-contactInfo.error'
  );
  const DistrictDetailsPath = `/domui/district/${districtId}/details`;
  const setUpdateData = async (updateData: DynamicData) => {
    await updateDistrictDetails(districtId, updateData, DistrictDetailsPath);
  };
  if (updatedError === null && updatedLoading === false && newData != null) {
    setUpdateFlag(true);
  }

  if (districtDataLoading || !districtData) {
    return <Loading />;
  }
  if (updatedLoading && !districtData) {
    return <Loading />;
  }

  const initialValues = mapDistrictDetailsToForm(districtData);

  const handleCancel = () => {
    localizedNavigate(DistrictDetailsPath);
  };

  const handleSubmit = async (inputValues: DynamicData) => {
    const values = { ...inputValues };

    values.primaryPhone = fillMissingValues(
      ensureArray(values?.primaryPhone),
      ensureArray(initialValues?.primaryPhone)
    );
    values.primaryFax = fillMissingValues(
      ensureArray(values?.primaryFax),
      ensureArray(initialValues?.primaryFax)
    );
    const updatedPhonesWithisPrimary: FormPhoneItem[] = [
      ...values.primaryPhone.map((item: Partial<FormPhoneItem>) =>
        createFilledItem(item, true, false)
      ),
      ...values.primaryFax.map((item: Partial<FormPhoneItem>) =>
        createFilledItem(item, false, true)
      ),
    ].filter(Boolean);

    const primaryEmail =
      initialValues?.emails?.filter(
        (email: { isPrimary: boolean }) => email.isPrimary
      ) || [];
    const updatedEmails = removeInvalidEmails(
      processContactInfoForDomino(primaryEmail, [
        createEmailWithID(
          values?.primaryEmail,
          values?.primaryEmailID,
          values?.primaryEmailIsPrimary,
          values?.primaryEmailType
        ),
      ]) || []
    );

    const primaryAddress =
      initialValues?.addresses?.filter(
        (address: { isPrimary: boolean }) => address.isPrimary
      ) || [];
    if (primaryAddress.length === 0 && values?.address) {
      values.address.isPrimary = true;
      values.address.type = 'Business';
    }
    const updatedAddress =
      processContactInfoForDomino(primaryAddress, [values?.address]) || [];

    const updatedPhones =
      processContactInfoForDomino(
        initialValues?.phones?.filter(
          (phone: { isPrimary: boolean; isFax: boolean }) =>
            !(phone?.isPrimary === false && phone?.isFax === false)
        ),
        updatedPhonesWithisPrimary
      ) || [];

    const websitesArray = ensureArray(initialValues.websites);
    const updatedWebsite =
      removeInvalidWebsites(
        processContactInfoForDomino(websitesArray, [
          createWebsiteWithID(
            values?.primaryWebsite,
            values?.primaryWebsiteID,
            values?.websites?.otherWebsites
          ),
        ])
      ) || [];

    const contactInfo = {
      contactInfo: {
        addresses: updatedAddress,
        emails: updatedEmails,
        phones: updatedPhones,
        websites: updatedWebsite,
      },
    };

    await setUpdateData(contactInfo);
  };

  return (
    <div className="mb-20 mt-10">
      <LinkPrevious
        path={DistrictDetailsPath}
        label={t('district-editcontact.back-link', 'Back')}
      />

      <Title>
        {t('district-editcontact.title', 'Edit Contact Information')}
      </Title>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={editDistrictContactValidationSchema(t)}
      >
        {({ values, isSubmitting, isValid }) => {
          const isPrimaryPhoneRequired = !!values.primaryPhone?.number;

          const primaryPhoneLabels = {
            phoneCode: t(
              'district-editcontact.phone-label-extension',
              'Phone Code'
            ),
            phoneNumber: t('district-editcontact.phone-label', 'Primary Phone'),
          };

          const isPrimaryFaxRequired = !!values.primaryFax?.number;

          const primaryFaxLabels = {
            phoneCode: t(
              'district-editcontact.fax-label-extension',
              'Phone Code'
            ),
            phoneNumber: t('district-editcontact.fax-label', 'Fax Number'),
          };

          return (
            <Form className="max-w-lg mt-16">
              <TextField
                name="primaryEmail"
                label={t('district-editcontact.email-label', 'Primary Email')}
              />

              <PhoneInput
                suffix="primaryPhone"
                isCountryFlagRequired={isPrimaryPhoneRequired}
                labels={primaryPhoneLabels}
                required
              />

              <PhoneInput
                suffix="primaryFax"
                isCountryFlagRequired={isPrimaryFaxRequired}
                labels={primaryFaxLabels}
                required
              />

              <TextField
                name="primaryWebsite"
                label={t('district-editcontact.website-label', 'Website')}
              />

              <AddressFields
                countryData={countryData}
                countryId={values?.address?.countryId}
                mainLabel={t(
                  'district-editcontact.address-label',
                  'Mailing Address'
                )}
                name={{ hasStates: 'address.hasStates' }}
                requiredFields={checkAddressFieldsRequired(values?.address)}
              />

              <FormButtons
                saveBtnText={t(
                  'district-editcontact.save-button-label',
                  'Save Changes'
                )}
                cancelBtnText={t(
                  'district-editcontact.cancel-button-label',
                  'Cancel'
                )}
                isCancelDisabled={isSubmitting}
                isSaveDisabled={isSubmitting || !isValid}
                cancel={handleCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditDistrictContact;
