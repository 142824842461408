import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Organization from '@domui-utils/workflow/organization';

// Custom hook to handle API calls
export const useDistrictAssistantGovernors = () => {
  const [data, setData] = useState<DynamicData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const districtAssistantGovernors = async (
    districtId: string,
    pageNumber: number,
    pageSize: number,
    requestBody: DynamicData
  ) => {
    try {
      if (districtId && requestBody) {
        setLoading(true);
        setError(null);
        const wfService = new Organization();
        const data = await wfService.DistrictAssistantGovernors(
          districtId,
          pageNumber,
          pageSize,
          requestBody
        );
        const fetchData = {
          totalCoun: 0,
          districtAssistantGovernorsDetails: {
            districtAssistantGovernors: [],
          },
        };
        const response = data?.status === 404 ? fetchData : data;
        setData(response);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, districtAssistantGovernors };
};
