/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import SelectRotaryYear from '@components/Forms/Select/SelectRotaryYear';
import LinkPrevious from '@components/LinkPrevious';

import ContainerWithLinks from '../Members/ContainerWithLinks';

import {
  buildAGsPageUrl,
  getOfficerTermSelectOptions,
} from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

// interface Props {
//   selectedYear: string | null;
//   handleYearChanged: (year: string) => void;
//   districtId: string;
//   locationState?: LocationStateType;
// }

export type LocationStateType = {
  isFromDetailsPage: Maybe<boolean>;
  path?: string;
};

const DistrictOfficersHeader: React.FC<any> = ({
  selectedYear,
  handleYearChanged,
  districtId,
  locationState,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <LinkPrevious
        path={
          locationState?.path ||
          (locationState?.isFromDetailsPage
            ? `/domui/district/${districtId}/details`
            : `domui/district/${districtId}/members`)
        }
        label={t('back-link-text', 'Back')}
      />
      <ContainerWithLinks
        className="desktop:mb-4"
        isAssistantGovernor
        assistantGovernorsUrl={buildAGsPageUrl(districtId)}
      />
      <div className="flex flex-wrap items-baseline">
        <div className="mt-2 tablet:mt-4 mb-0 tablet:mb-6 mr-auto text-xl font-extralight leading-xl">
          {t('district-officers.title', 'District Officers')}
        </div>
        <div>
          <SelectRotaryYear
            handleChange={handleYearChanged}
            options={getOfficerTermSelectOptions()}
            initialValue={selectedYear}
          />
        </div>
      </div>
    </>
  );
};

export default DistrictOfficersHeader;
