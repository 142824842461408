/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import LinkEdit from '@components/LinkEdit';
import MailingAddress from '@presenters/web/components/MailingAddress';
import TitleLink from '@presenters/web/components/TitleLink';

import ContactInformationPhone from './ContactInformationPhone';
import Section from './Section';

import { DynamicData } from 'src/domui/domain/type';

import { useTranslation } from '@external/react-i18next';

import { PhoneNumber } from '@typings/graphql';

interface ContactsViewProps {
  districtDetails: DynamicData;
  districtId: string;
  hasPermissionsToEditContactInfo: boolean;
}

const ContactsView: React.FC<ContactsViewProps> = ({
  districtDetails,
  districtId,
  hasPermissionsToEditContactInfo,
}) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<DynamicData | null>(null);

  useEffect(() => {
    const fetchDetails = async () => {
      // Simulate fetching data
      const data = await Promise.resolve(districtDetails);
      setDetails(data);
    };

    fetchDetails();
  }, [districtDetails]);

  if (!details) {
    return <div>Loading...</div>;
  }

  const {
    id,
    districtLegalName,
    zone,
    zoneSection,
    emails,
    phones,
    websites,
    addresses,
  } = details;

  const editContactPath = `/domui/district/${districtId}/details/edit-contact`;
  const titleText = t(
    'district-details.contacts-heading',
    'Contact Information'
  );

  const noDetailsInSection = t('district-details.no-details', 'None');
  const isLegalDescriptionSectionShown =
    districtLegalName && (zone || zoneSection);
  // const address = addresses?.[0];
  const primaryWebsite = details?.websites?.primaryWebsite || '';
  const primaryEmails =
    emails?.filter((email: { isPrimary: boolean }) => email?.isPrimary) || [];
  const primaryPhones =
    phones?.filter(
      (phone: { isPrimary: boolean; isFax: boolean }) =>
        phone?.isPrimary && !phone?.isFax
    ) || [];
  const faxPhones =
    phones?.filter((phone: { isFax: boolean }) => phone?.isFax) || [];

  const primaryAddresses =
    addresses?.find((address: { isPrimary: boolean }) => address?.isPrimary) ||
    '';

  return (
    <div className="mb-8">
      <div className="flex justify-between mr-10">
        <TitleLink title={titleText} isArrowShown={false} />
        {hasPermissionsToEditContactInfo && (
          <LinkEdit path={editContactPath} component={titleText} />
        )}
      </div>
      <Section title={t('district-details.description', 'Legal Description')}>
        {isLegalDescriptionSectionShown
          ? t(
              'district-details.legalName',
              '{{districtLegalName}}; Zone {{zone}}',
              { districtLegalName, zone: zone || zoneSection }
            )
          : noDetailsInSection}
      </Section>

      <div className="my-4">
        <h5>{t('district-details.email-primary', 'Email Address')}</h5>
        {primaryEmails && primaryEmails.length > 0 ? (
          primaryEmails.map((email: { email: string; isPrimary: boolean }) => (
            <span key={email.email}>{email.email}</span>
          ))
        ) : (
          <span className="mb-6 text-xs text-gray-600">None</span>
        )}
      </div>

      <div className="my-4">
        <h5>
          {t('club-details.contact-information.phone-number-label', 'Phone')}
        </h5>
        {primaryPhones && primaryPhones.length > 0 ? (
          <span>
            {primaryPhones.map((phone: PhoneNumber) => (
              <ContactInformationPhone phone={phone} key={phone.number} />
            ))}
          </span>
        ) : (
          <span className="mb-6 text-xs text-gray-600">None</span>
        )}
      </div>

      <div className="my-4">
        <h5>{t('club-details.contact-information.fax-number-label', 'Fax')}</h5>
        {faxPhones && faxPhones.length > 0 ? (
          <span>
            {faxPhones.map((phone: PhoneNumber) => (
              <ContactInformationPhone phone={phone} key={phone.number} />
            ))}
          </span>
        ) : (
          <span className="mb-6 text-xs text-gray-600">None</span>
        )}
      </div>

      <Section title={t('district-details.website', 'Website')}>
        {primaryWebsite ? (
          <a href={primaryWebsite} target="_blank" rel="noreferrer">
            {primaryWebsite}
          </a>
        ) : (
          noDetailsInSection
        )}
      </Section>

      <Section title={t('district-details.address-mailing', 'Mailing Address')}>
        {primaryAddresses ? (
          <MailingAddress {...primaryAddresses} />
        ) : (
          noDetailsInSection
        )}
      </Section>
    </div>
  );
};

export default ContactsView;
