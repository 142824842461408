import React from 'react';

import { ContextMenu } from '@components/ContextMenu';

// import {
//   // buildAGsPageUrl,
//   // defaultContextState,
//   // DistrictContext,
//   // getAGContextMenuItems,
// } from '@use-cases/districts';

import { constructFullName } from '@utils/constructFullName';
// import { localizedNavigate } from '@utils/localized-navigate';

import ClubsList from '@domui-components/ClubsList';
import MemberRow from '@domui-components/MemberRow';
import {
  Affiliations,
  Club,
  // AGManagerInfo,
  // ASSISTANT_GOVERNOR,
  // ASSISTANT_GOVERNOR_ROLE_ID,
  // CommitteeAppointmentPartialData,
  DistrictAG,
  // mapAGToCommitteeMemberData,
  // OperationType,
  // Step,
  // AssistantGovernor,
} from '@domui-domain/districts';
import {
  buildAGsPageUrl,
  // defaultContextState,
  // DistrictContext,
  // getAGContextMenuItems,
} from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { Maybe, MembershipType } from '@typings/graphql';
// import { Club } from '@typings/operations';

// type AGManagerInfo = {
//   afterNowTerm: boolean;
//   isCurrentYearManager: boolean;
//   isManagerAfterNow: boolean;
//   isManagerNow: boolean;
// };

interface Props {
  districtId: string;
  agItem: DistrictAG;
  // riDistrictId: number;
  // managerInfo: AGManagerInfo;
  // riDistrictId?: Maybe<number>;
}

const AGMemberItem: React.FC<Props> = ({
  districtId,
  agItem,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // riDistrictId,
  // managerInfo,
  // riDistrictId,
}) => {
  const {
    id,
    email,
    phone,
    photoUri,
    localizedName,
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    membershipClubs,
    clubAffiliations,
    isDeceased = false,
  } = agItem;
  const { t } = useTranslation();

  const isActiveMember = membershipClubs.some(
    ({ membershipType, terminationDate }) =>
      membershipType === MembershipType.Member && !terminationDate
  );

  const fullName = constructFullName({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  });
  // const [
  //   {
  //     // districtId,
  //     selectedInfo: { term },
  //   },
  //   // setContext,
  // ] = useContext(DistrictContext);

  // const getAGMember = mapAGToCommitteeMemberData(agItem, fullName);

  const clubAssignedButtonText = `${clubAffiliations?.length} ${
    clubAffiliations?.length === 1
      ? t('club-assigned', 'Club Assigned')
      : t('clubs-assigned', 'Clubs Assigned')
  }`;

  const getNormalizedClub = (clubAffiliations: Affiliations[]) =>
    clubAffiliations?.map(item => ({
      ...item,
      physicalLocation: item.location,
    })) as Club[];

  // const handleClickMenu = () =>
  // AGsManagerURL: string,
  // operationType: OperationType
  // {
  // const updatedState: CommitteeAppointmentPartialData = {
  //   // step: 1,
  //   // operationType,
  //   selectedInfo: {
  //     ...defaultContextState.selectedInfo,
  //     // role: {
  //     //   id: ASSISTANT_GOVERNOR_ROLE_ID,
  //     //   name: ASSISTANT_GOVERNOR,
  //     // },
  //   },
  // };
  // const isReassign = operationType === OperationType.REASSIGN_TO_AG;
  // if (isReassign) {
  //   updatedState.step = 2;
  //   updatedState.selectedInfo.assignee = getAGMember;
  // } else if (
  //   [
  //     OperationType.UNASSIGN_OFFICER_FROM_ROLE,
  //     OperationType.REPLACE_AG,
  //   ].includes(operationType)
  // ) {
  //   updatedState.selectedInfo.unassignFrom = getAGMember;
  // }
  // setContext(prevState => ({
  //   ...prevState,
  //   // step: updatedState.step as Step,
  //   // operationType: updatedState.operationType,
  //   selectedInfo: {
  //     ...prevState.selectedInfo,
  //     ...updatedState.selectedInfo,
  //     // term: isReassign
  //     //   ? prevState.selectedInfo.term
  //     //   : prevState.termsToAssign[0],
  //   },
  // }));
  // localizedNavigate(AGsManagerURL, {
  //   state: {
  //     backUrl: buildAGsPageUrl(districtId),
  //   },
  // });
  // };

  // const contextMenuItems = getAGContextMenuItems(t, handleClickMenu, {
  //   districtId,
  //   riDistrictId,
  //   agItem,
  //   // managerInfo,
  //   // isActiveMember,
  //   // getAGMember,
  //   term,
  // });

  // const isContextMenuItemsExist = !!contextMenuItems.length;

  const sortedClubsByName = getNormalizedClub(
    clubAffiliations
  ).sort(
    (
      { clubName: clubNameA }: Affiliations,
      { clubName: clubNameB }: Affiliations
    ) => clubNameA.localeCompare(clubNameB)
  );

  const getMemberInfo = (isDeceased: Maybe<boolean>) => {
    if (isDeceased) {
      return (
        <p className="mb-0">
          {t('assistant-governor-member.role-deceased', 'Deceased')}
        </p>
      );
    }
    return undefined;
  };

  return (
    <MemberRow
      id={id}
      email={!isDeceased ? email : null}
      fullName={fullName}
      localizedName={localizedName || ''}
      photoUri={photoUri || ''}
      phoneNumber={!isDeceased ? phone : null}
      associatedClubsInfo={getNormalizedClub(clubAffiliations)}
      isLinkToProfile={isActiveMember && !isDeceased}
      memberInfo={getMemberInfo(isDeceased)}
      clubAffiliations={
        <ContextMenu
          optionsToggleButton
          isRightIndent={false}
          title={fullName}
          classesMenuContainer="desktop:max-w-xs left-0 right-0 m-0 overflow-y-auto max-h-96"
          className="desktop:ml-0"
          customContent={
            clubAffiliations?.length ? (
              <ClubsList classes="p-5" clubs={sortedClubsByName} />
            ) : null
          }
          customToggleButton={
            <span className="text-dark-blue-400 underline cursor-pointer">
              {clubAssignedButtonText}
            </span>
          }
        />
      }
      // actionButton={
      //   !isDeceased &&
      //   isContextMenuItemsExist && (
      //     <ContextMenu title={fullName || ''} items={contextMenuItems} />
      //   )
      // }
      // actionButtonMobile={
      //   !isDeceased &&
      //   isContextMenuItemsExist && (
      //     <ContextMenu
      //       title={fullName || ''}
      //       items={!isDeceased ? contextMenuItems : null}
      //       isRightIndent={false}
      //       optionsToggleButton
      //     />
      //   )
      // }
      profilePreviousLinkPath={buildAGsPageUrl(districtId)}
    />
  );
};

export default AGMemberItem;
