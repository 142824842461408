// Libs
import React from 'react';

type Props = {
  x?: number;
  y?: number;
  index?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

const textProps = (xValue = 0, y = 0) => ({
  textAnchor: 'start',
  transform: `translate(${xValue},${y + 15})`,
  fontSize: '.875rem',
});

const getXValue = (x = 0, value = 0, index = 0) => {
  const valueLength = String(value).length;
  const lastIndex = 4;

  if (index === 0) {
    return x;
  }

  if (index === lastIndex) {
    return valueLength > 2 ? x - 17 : x - 5;
  }

  if (valueLength > 3) {
    return x - 12.5;
  }

  return x - 10;
};

const DetailsChartXAxisTick: React.FC<Props> = ({
  x = 0,
  y = 0,
  index = 0,
  payload: { value },
}) => (
  <svg>
    <g
      data-testid="detailsChartXAxisTick"
      {...textProps(getXValue(x, value, index), y)}
    >
      {index % 2 === 0 && <text>{value}</text>}
    </g>
  </svg>
);

export default DetailsChartXAxisTick;
