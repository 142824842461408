import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';

type RotaryYearProps = {
  endDate: string;
  isCurrentRotaryYear: boolean;
  rotaryFullYear: string;
  rotaryYear: number;
  startDate: string;
};

export const getYearOptionsForSelect = (): {
  value: string;
  label: string;
}[] => {
  const { rotaryYear } = getClubDistrictFromLocal();
  // eslint-disable-next-line array-callback-return, consistent-return
  return rotaryYear.map((value: RotaryYearProps) => ({
    value: value.rotaryYear,
    label: value.rotaryFullYear,
  }));
};
