import { TFunction } from 'i18next';
import * as yup from 'yup';

import addressValidationSchema, {
  addressLineValidation,
} from '@components/Formik/Address/validation';
import {
  emailSchema,
  numberSchema,
} from '@components/Formik/validation/fieldDefinitions';
import {
  hasMaxLength,
  isRequired,
  isURL,
  matchLatinChars,
  matchValidStartCharacter,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

import { DistrictDetailsFormValues } from '@domain/districts';
// import { postalCodeValidation } from '@domui-components/Formik/Address/validation';

export const checkAddressFieldsRequired = (
  values: DistrictDetailsFormValues['address']
) => {
  const { lineOne, lineTwo, lineThree, countryId, city, postalCode } =
    values || {};
  const addressFieldsNotEmpty = Boolean(lineOne || lineTwo || lineThree);
  const isAddressRequired = Boolean(
    addressFieldsNotEmpty || countryId || city || postalCode
  );

  return {
    lineOne: isAddressRequired,
    country: isAddressRequired,
    city: isAddressRequired,
    postalCode: isAddressRequired,
  };
};

const checkOneOfFieldsFilled = (...fields: string[]) => fields.some(Boolean);

export const editDistrictContactValidationSchema = (t: TFunction) =>
  yup.object().shape({
    address: addressValidationSchema(t).shape(
      {
        lineOne: addressLineValidation(t)
          .when(['countryId', 'city', 'postalCode', 'lineTwo', 'lineThree'], {
            is: checkOneOfFieldsFilled,
            then: yup
              .string()
              .required(
                t(
                  'form.address.error_line1_required',
                  'Address line 1 cannot be empty'
                )
              ),
          })
          .nullable(),

        countryId: yup
          .string()
          .when(['city', 'postalCode', 'lineOne', 'lineTwo', 'lineThree'], {
            is: checkOneOfFieldsFilled,
            then: schemaField(t, yup.string(), isRequired),
          })
          .nullable(),

        city: schemaField(
          t,
          yup.string(),
          matchLatinChars,
          matchValidStartCharacter,
          hasMaxLength(40)
        )
          .when(
            ['countryId', 'postalCode', 'lineOne', 'lineTwo', 'lineThree'],
            {
              is: checkOneOfFieldsFilled,
              then: schemaField(t, yup.string(), isRequired),
            }
          )
          .nullable(),

        postalCode: yup
          .string()
          .required(t('form.address.error_postal_code_required', 'Required'))
          .nullable(),
      },

      [
        ['lineOne', 'countryId'],
        ['lineOne', 'city'],
        ['postalCode', 'countryId'],
        ['countryId', 'city'],
      ]
    ),
    primaryEmail: emailSchema(t),
    primaryWebsite: schemaField(t, yup.string(), isURL),
    primaryPhone: yup.object().shape({
      country: yup.string().when('number', {
        is: (phoneNumber: string) => !!phoneNumber,
        then: schemaField(t, yup.string(), isRequired),
      }),
      number: numberSchema(t),
    }),
    primaryFax: yup.object().shape({
      country: yup.string().when('number', {
        is: (faxNumber: string) => !!faxNumber,
        then: schemaField(t, yup.string(), isRequired),
      }),
      number: numberSchema(t),
    }),
  });
