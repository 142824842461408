import React from 'react';

import classNames from 'classnames';

import { Avatar } from '@components/Avatar';
import IndividualListColumn from '@components/IndividualListColumn';

import { MemberRowProps } from './types';

const RowDesktop: React.FC<MemberRowProps> = ({
  id,
  email,
  fullName,
  localizedName,
  photoUri,
  phoneNumber,
  associatedClubsInfo,
  clubAffiliations,
  memberInfo,
  terms,
  actionButton,
  infoMessage,
  roleTitle,
  unAssignMessage,
  unassigned = false,
  isLinkToProfile = true,
  isTermsColumn = false,
  isAssigned3yrRoleInFutureRY = false,
  wrapperClass = '',
  hasNoBorder = false,
  haveMemberships,
  activeMemberships,
  isSelectMemberTab,
  profilePreviousLinkPath,
}) => {
  const isSelfCenter = !haveMemberships;
  const isSelfStart = haveMemberships;
  const isSeveralMemberships =
    associatedClubsInfo && associatedClubsInfo.length > 1;
  const isIndividualListWidthFull =
    !email && (isTermsColumn ? !terms : !clubAffiliations);
  return (
    <div
      className={classNames(
        `hidden desktop:flex flex-1 flex-row ${wrapperClass}`,
        {
          'items-center': isAssigned3yrRoleInFutureRY,
          'member-row-desktop': isSelfCenter,
          'h-fit': isSelfStart,
        }
      )}
      data-testid="member-row-desktop"
    >
      <Avatar
        unassigned={unassigned}
        unassignedIcon="users/avatar-unassigned"
        alt={fullName || ''}
        photoUri={photoUri}
        className={classNames(
          'flex justify-center member-row-avatar desktop:w-20',
          {
            'self-center': !(isSeveralMemberships || haveMemberships),
            'self-start': isSeveralMemberships || haveMemberships,
          }
        )}
      />
      <div
        className={classNames('w-full member-row-info', {
          'no-border': hasNoBorder,
        })}
      >
        <div
          className={classNames('flex flex-row w-full items-center', {
            'h-full': !infoMessage,
          })}
        >
          <div
            className={classNames('w-20 mr-auto flex-auto', {
              'w-full': isIndividualListWidthFull,
              'self-center': isSelfCenter,
              'self-start': isSelfStart,
            })}
          >
            <IndividualListColumn
              fullName={fullName}
              photoUri={photoUri}
              id={id}
              localizedName={localizedName}
              associatedClubsInfo={associatedClubsInfo}
              roleTitle={roleTitle}
              terms={!isTermsColumn && terms}
              extraMessage={unAssignMessage}
              showAvatar={false}
              isLinkToProfile={isLinkToProfile}
              hasMemberships={haveMemberships}
              activeMemberships={activeMemberships}
              isSelectMemberTab={isSelectMemberTab}
              profilePreviousLinkPath={profilePreviousLinkPath}
            >
              {memberInfo}
            </IndividualListColumn>
          </div>
          <div
            className={classNames(
              'flex flex-col flex-1 text-xs leading-6 w-24 mr-4',
              {
                'self-center': isSelfCenter,
                'self-start': isSelfStart,
              }
            )}
          >
            {isTermsColumn ? terms : clubAffiliations}
          </div>
          <div
            className={classNames(
              'flex flex-col flex-1 text-xs leading-6 w-16 mr-4',
              {
                'self-center': isSelfCenter,
                'self-start': isSelfStart,
              }
            )}
          >
            {email && (
              <p className="text-xs mb-0 truncate">
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            )}
            {phoneNumber && (
              <p className="text-xs mb-0 text-gray-600 truncate">
                <a className="link-styles-off" href={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </a>
              </p>
            )}
          </div>
          <div
            className={classNames({
              'self-center': isSelfCenter,
              'self-start pt-6': isSelfStart,
            })}
          >
            {!actionButton ? (
              <div className="relative ml-auto mr-11 self-center" />
            ) : (
              actionButton
            )}
          </div>
        </div>
        {infoMessage && <div className="mt-2">{infoMessage}</div>}
      </div>
    </div>
  );
};

export default RowDesktop;
