import React, { CSSProperties } from 'react';

import classNames from 'classnames';

import tailwind from '../../../../styles/tailwind';
import { FormElement } from '../FormElement';

import { useTranslation } from '@external/react-i18next';
import ReactSelect, {
  components,
  GroupedOptionsType,
  OptionsType,
  OptionTypeBase,
  StylesConfig,
  ValueType,
} from '@external/react-select';

type SelectState = {
  isSelected?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
};

export const Select: React.FC<{
  id: string;
  name: string;
  options?: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
  noOptionsMessage?: string;
  label: string;
  labelHidden?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isRtl?: boolean;
  onDarkBackground?: boolean;
  withDropdownLabel?: boolean;
  description?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  noMargins?: boolean;
  errorMessage?: string;
  ref?: (instance: ReactSelect<OptionTypeBase, never>) => void;
  value?: ValueType<OptionTypeBase>;
  defaultValue?: ValueType<OptionTypeBase>;
  getValue?: (value: string) => void;
  onChange?: (selectedOption: ValueType<OptionTypeBase>) => void;
  onInputChange?: (value: string) => void;
  inputValue?: string;
  isSeparator?: boolean;
  elementWrapper?: string;
  prefix?: React.ReactNode;
  labelClassName?: string;
  isSelectedOptionBold?: boolean;
  controlCustomStyles?: CSSProperties | object;
  indicatorsCustomStyles?: CSSProperties | object;
  valueCustomStyles?: CSSProperties | object;
  menuCustomStyles?: CSSProperties | object;
}> = ({
  id,
  name,
  options,
  noOptionsMessage,
  label,
  labelHidden,
  placeholder,
  isMulti,
  isLoading,
  isClearable,
  isSearchable,
  isRtl,
  onDarkBackground,
  withDropdownLabel,
  description,
  className = '',
  noMargins,
  required,
  disabled,
  error,
  errorMessage,
  ref,
  value,
  defaultValue,
  getValue,
  onChange,
  onInputChange,
  inputValue,
  elementWrapper,
  prefix,
  isSeparator = true,
  controlCustomStyles,
  valueCustomStyles,
  indicatorsCustomStyles,
  menuCustomStyles,
  isSelectedOptionBold = false,
  labelClassName = '',
}) => {
  const { t } = useTranslation();

  const getOptionBgColor = ({ isSelected, isFocused }: SelectState) => {
    if (isSelected) {
      return tailwind.theme.colors['bright-blue']['600'];
    }
    if (isFocused) {
      return tailwind.theme.colors.gray['200'];
    }
    return tailwind.theme.colors.white;
  };

  const customStyles = (
    controlStyles?: CSSProperties,
    indicatorsStyles?: CSSProperties,
    valueStyles?: CSSProperties,
    menuStyles?: CSSProperties,
    isSelectedBold?: boolean
  ) => ({
    control: (provided: CSSProperties) => ({
      ...provided,
      border:
        onDarkBackground && !error
          ? 'none'
          : `1px solid ${
              error
                ? tailwind.theme.colors.red['600']
                : tailwind.theme.colors.gray['300']
            }`,
      borderRadius: '.125rem',
      padding: '0.1875rem 0.5rem',
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': {
        borderColor: tailwind.theme.colors['dark-blue'][400],
      },
      ...controlStyles,
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      ...menuStyles,
      marginTop: '1px',
    }),
    menuList: (provided: CSSProperties) => ({
      ...provided,
    }),
    input: (provided: CSSProperties, state: SelectState) => ({
      ...provided,
      background: state.isDisabled
        ? tailwind.theme.colors.gray['100']
        : tailwind.theme.colors.white,
    }),
    placeholder: (provided: CSSProperties, state: SelectState) => ({
      ...provided,
      color: state.isDisabled
        ? tailwind.theme.colors.gray['300']
        : tailwind.theme.colors.gray['400'],
    }),
    dropdownIndicator: (provided: CSSProperties, state: SelectState) => ({
      ...provided,
      color: state.isDisabled
        ? tailwind.theme.colors.gray['300']
        : tailwind.theme.colors['bright-blue']['600'],
    }),
    option: (provided: CSSProperties, state: SelectState) => ({
      ...provided,
      backgroundColor: getOptionBgColor(state),
      cursor: 'pointer',
      fontWeight: state.isSelected && isSelectedBold ? '700' : 'initial',
    }),
    indicatorSeparator: (provided: CSSProperties) => ({
      ...provided,
      backgroundColor: tailwind.theme.colors.gray['300'],
      marginTop: '6px',
      marginBottom: '6px',
      marginLeft: '8px',
    }),
    clearIndicator: () => ({
      background: tailwind.theme.colors.gray['300'],
      color: tailwind.theme.colors.white,
      borderRadius: '100%',
      lineHeight: 1,
    }),
    indicatorsContainer: (provided: CSSProperties) => ({
      ...provided,
      ...indicatorsStyles,
    }),
    valueContainer: (provided: CSSProperties) => ({
      ...provided,
      ...valueStyles,
    }),
  });

  const fauxLabel = t('forms.select.fauxLabel', 'Filter by {{label}}', {
    label,
  });
  const groupedOptions = [
    {
      label: fauxLabel,
      options,
    },
  ];
  const formatGroupLabel = () => (
    <p className="normal-case text-gray-300 font-bold text-xs">{fauxLabel}</p>
  );

  const getNoOptionsMessage = () => {
    let result = t('search.select-no-options-default', 'No results');
    if (noOptionsMessage && !options?.length && !inputValue) {
      result = noOptionsMessage;
    }
    return result;
  };

  return (
    <FormElement
      id={id}
      label={label}
      labelHidden={labelHidden}
      description={description}
      className={className}
      noMargins={noMargins}
      required={required}
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      classes={{ elementWrapper, label: labelClassName }}
      prefix={prefix}
    >
      <ReactSelect
        id={`input-select-${id}`}
        className={classNames('Select text-xs', {
          'cursor-not-allowed': disabled,
        })}
        styles={
          customStyles(
            controlCustomStyles,
            indicatorsCustomStyles,
            valueCustomStyles,
            menuCustomStyles,
            isSelectedOptionBold
          ) as StylesConfig
        }
        options={withDropdownLabel ? groupedOptions : options}
        noOptionsMessage={getNoOptionsMessage}
        isMulti={isMulti}
        getValue={getValue}
        name={name}
        required={required}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isRtl={isRtl}
        value={value}
        ref={ref}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isLoading={isLoading}
        onChange={onChange}
        onInputChange={onInputChange}
        placeholder={placeholder}
        formatGroupLabel={formatGroupLabel}
        components={{
          IndicatorSeparator: isSeparator
            ? components.IndicatorSeparator
            : null,
        }}
      />
    </FormElement>
  );
};
