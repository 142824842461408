import React, { useState } from 'react';

import Collapse from '@kunukn/react-collapse';
import classNames from 'classnames';

import { Avatar } from '@components/Avatar';
import Icon from '@components/Icon';
import IndividualListColumn from '@components/IndividualListColumn';

import { MemberRowProps } from './types';

const RowMobile: React.FC<MemberRowProps> = ({
  id,
  email,
  fullName,
  localizedName,
  photoUri,
  phoneNumber,
  associatedClubsInfo,
  clubAffiliations,
  memberInfo,
  terms,
  actionButton,
  infoMessage,
  roleTitle,
  unAssignMessage,
  unassigned = false,
  actionButtonMobile,
  isLinkToProfile = true,
  isTermsColumn = false,
  isOpened = false,
  hasCollapsePartsMobile = true,
  wrapperClass = '',
  hasNoBorder,
  haveMemberships,
  activeMemberships,
  isSelectMemberTab,
  profilePreviousLinkPath,
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpened);

  return (
    <div
      data-testid="member-row-mobile"
      className={classNames(
        `member-row-mobile ${wrapperClass} desktop:hidden w-full px-5`,
        {
          'bg-bright-blue-100 pb-8 -my-4 pt-5': isExpanded,
          'member-row-mobile--expanded': !unassigned && isExpanded,
        }
      )}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() =>
          hasCollapsePartsMobile && !unassigned && setIsExpanded(!isExpanded)
        }
        className={classNames(
          'relative w-full member-row-info flex items-center text-left text-xs font-normal leading-6 focus:shadow-none tap-highlight-color-transparent overflow-hidden',
          { 'no-border': hasNoBorder }
        )}
        data-testid="toggle-expand-btn"
      >
        <Avatar
          unassigned={unassigned}
          unassignedIcon="users/avatar-unassigned"
          alt={fullName || ''}
          photoUri={photoUri}
          className={classNames(
            'flex justify-center member-row-avatar desktop:w-20 self-start'
          )}
        />
        <div className="w-4/6">
          <div
            className={classNames('mr-auto self-center flex-auto', {
              'w-full': !email && !clubAffiliations,
            })}
          >
            <IndividualListColumn
              fullName={fullName}
              photoUri={photoUri}
              id={id}
              localizedName={localizedName}
              associatedClubsInfo={associatedClubsInfo}
              roleTitle={roleTitle}
              terms={!isTermsColumn && terms}
              extraMessage={unAssignMessage}
              showAvatar={false}
              isLinkToProfile={isLinkToProfile}
              hasMemberships={haveMemberships}
              activeMemberships={activeMemberships}
              isMobileExpanded={isExpanded}
              isSelectMemberTab={isSelectMemberTab}
              profilePreviousLinkPath={profilePreviousLinkPath}
            >
              {memberInfo}
            </IndividualListColumn>
            {infoMessage && <div className="mt-2">{infoMessage}</div>}
          </div>
          {!unassigned && hasCollapsePartsMobile && (
            <div
              aria-hidden="true"
              className={classNames(
                'absolute right-0 absolute-vertical-center',
                {
                  hidden: isExpanded,
                }
              )}
            >
              <Icon name="arrows/down" size="17" color="black" />
            </div>
          )}
          {unassigned && (
            <div className="absolute right-0 absolute-vertical-center">
              {actionButton}
            </div>
          )}
          {hasCollapsePartsMobile && (
            <div
              aria-hidden="true"
              className={classNames('absolute right-0 top-1', {
                hidden: !isExpanded,
              })}
            >
              <Icon name="ui/close" size="17" color="black" />
            </div>
          )}
        </div>
      </div>
      {hasCollapsePartsMobile && (
        <Collapse isOpen={isExpanded} className="collapse-css-transition">
          <div className="w-full overflow-hidden">
            <div className="pl-16 pr-8 leading-6 mt-6">
              {isTermsColumn ? terms : clubAffiliations}
            </div>
            <div className="pl-16 pr-8 text-xs leading-6 mt-6">
              {email && (
                <p
                  className={classNames('text-xs mb-0 text-dark-blue-400', {
                    truncate: !isExpanded,
                  })}
                >
                  {email}
                </p>
              )}
              {phoneNumber && (
                <p
                  className={classNames('text-xs mb-0', {
                    truncate: !isExpanded,
                  })}
                >
                  {phoneNumber}
                </p>
              )}
            </div>
          </div>
          <div className="self-center">{actionButtonMobile}</div>
        </Collapse>
      )}
    </div>
  );
};

export default RowMobile;
