/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React from 'react';

import { ROLES_REQUIRED_BY_POLICY } from '@domain/districts';

// import { useTranslation } from '@external/react-i18next';

// Constants

// Types
// import { DistrictOfficerRole } from '@domain/districts';
// import { DistrictOfficer } from '@typings/operations';

const DistrictOfficerRoleTitleDesktop = ({ role }: any): any => {
  // const { t } = useTranslation();
  return (
    <div className="h5 alternate">
      {role}
      {ROLES_REQUIRED_BY_POLICY.includes(role) ? '*' : ''}
      {/* {termYears > 1 &&
        ` (${t('district-officers.year-term', '{{termYears}}-year-term', {
          termYears,
        })})`} */}
    </div>
  );
};

export default DistrictOfficerRoleTitleDesktop;
