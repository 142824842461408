/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import Collapse from '@kunukn/react-collapse';

import CollapsibleHeader from '@components/CollapsibleHeader/';
import { ResultsListItem } from '@components/ResultsList';

import DistrictOfficerRoleItem from './DistrictOfficerRoleItem';

// import {
//   //DistrictOfficerRole,
//   // UnassignedDistrictOfficerRoleTerm,
// } from '@domain/districts';

import { isRoleAssignedInFutureRY } from '@use-cases/districts';

import { OFFICERS_ROLES } from '@domui-domain/districts/constants';
import {
  getActiveDO,
  sortItemsByRequiredOrder,
} from '@domui-use-cases/districts';

// interface Props {
//   title: string;
//   notice?: string;
//   roles: DistrictOfficerRole<DistrictOfficer>[];
//   assignedFuture3yrTermRoleIds?: string[];
// }

const DistrictOfficerRolesList: React.FC<any> = ({
  title,
  notice,
  leadershipData,
  officers,
  assignedFuture3yrTermRoleIds,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const rolesCount = leadershipData?.length;
  const filteredArray: any = officers?.filter(
    (individual: { districtLeadership: { roleId: any }[] }) =>
      individual.districtLeadership.some((leadership: { roleId: any }) =>
        sortItemsByRequiredOrder(
          getActiveDO(leadershipData),
          OFFICERS_ROLES,
          'role'
        ).some((role: { roleId: any }) => role.roleId === leadership.roleId)
      )
  );

  return (
    <div className="mt-10 first:mt-2">
      <CollapsibleHeader
        isOpen={isOpen}
        title={title}
        notice={notice}
        count={rolesCount}
        toggleCollapse={() => setIsOpen(!isOpen)}
      />
      {/* We should add the `overflowOnExpanded` property to the Collapse component for proper overflow handling */}
      <Collapse
        isOpen={isOpen}
        className="collapse-css-transition"
        overflowOnExpanded
      >
        <ul>
          {filteredArray?.map(
            ({
              id,
              prefix,
              firstName,
              middleName,
              lastName,
              suffix,
              districtLeadership,
              primaryEmail,
              photoUri,
              primaryPhone,
              membership,
            }: any) => (
              <ResultsListItem key={id} columnLayout>
                <DistrictOfficerRoleItem
                  // key={key}
                  prefix={prefix}
                  firstName={firstName}
                  middleName={middleName}
                  lastName={lastName}
                  suffix={suffix}
                  role={districtLeadership}
                  districtLeadership={districtLeadership}
                  membership={membership}
                  primaryEmail={primaryEmail}
                  primaryPhone={primaryPhone}
                  photoUri={photoUri}
                  assignedTerm={districtLeadership as any}
                  isAssignedInFutureRY={isRoleAssignedInFutureRY(
                    assignedFuture3yrTermRoleIds || [],
                    id
                  )}
                />
              </ResultsListItem>
            )
          )}
        </ul>
      </Collapse>
    </div>
  );
};

export default DistrictOfficerRolesList;
