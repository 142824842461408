import React, { useState } from 'react';

import {
  controlCustomStyles,
  indicatorsCustomStyles,
  menuCustomStyles,
  valueCustomStyles,
} from './customStyles';

import { Select } from '@domui-components/Forms/Select';
import { getCurrentDate } from '@domui-utils/datetime';
import { getYearOptionsForSelect } from '@domui-utils/getYearOptionsForSelect';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase, ValueType } from '@external/react-select';

interface Props {
  handleChange: (selectedOption: string) => void;
  options?: OptionTypeBase[];
  initialValue?: string;
}

const SelectRotaryYear: React.FC<Props> = ({
  handleChange,
  options = getYearOptionsForSelect(),
  initialValue = getCurrentDate(),
}) => {
  const selectorName = 'select-rotary-year';
  const [date, setDate] = useState<string>(initialValue);
  const { t } = useTranslation();

  const onChange = (item: ValueType<OptionTypeBase>) => {
    const selectedOption = item as OptionTypeBase;
    setDate(selectedOption.value);
    handleChange(selectedOption.value);
  };

  return (
    <span className="flex">
      <span className="mt-3 flex items-center text-xs text-gray-500">
        {t('select-rotary-year.term', 'Term')}:
      </span>
      <span>
        <Select
          isSeparator={false}
          isSearchable={false}
          labelHidden
          isSelectedOptionBold
          label={selectorName}
          id={selectorName}
          noMargins
          name={selectorName}
          options={options}
          onChange={onChange}
          defaultValue={options.find(item => item.value === date)}
          controlCustomStyles={controlCustomStyles}
          indicatorsCustomStyles={indicatorsCustomStyles}
          valueCustomStyles={valueCustomStyles}
          menuCustomStyles={menuCustomStyles}
        />
      </span>
    </span>
  );
};

export default SelectRotaryYear;
