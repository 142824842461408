/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import { pipe } from 'fp-ts/function';
import { curry } from 'lodash';
import moment from 'moment';

// Utils
import { getRotaryYear } from '@utils/datetime';

// Types
import { DistrictOfficer as DO } from '@typings/graphql';

export const filterDOByDelegatedOnly = (officers: any): any =>
  officers.filter(({ isDelegated }: any) => !isDelegated);

export const filterDOsByDate = (year: string, officers: DO[]): DO[] =>
  officers.filter(
    ({ endDate }) =>
      !(year === getRotaryYear() && moment(endDate).isBefore(moment(), 'day'))
  );

export const getActiveDO = (officers: any) =>
  pipe(
    officers,
    filterDOByDelegatedOnly,
    curry(filterDOsByDate)(getRotaryYear())
  );
