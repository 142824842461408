/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React from 'react';

// import { find } from 'lodash';

import Loading from '@components/Loading';
// Components
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import ClubItem from './ClubItem';
import ClubsListHeader from './ClubsListHeader';
// import Filters from './Filters';

// import { ClubFilterProps } from '@domain/districts';

// Utils
import { useTranslation } from '@external/react-i18next';

export type Maybe<T> = T | null;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};
type DistrictClub = {
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  nfKey: Scalars['String'];
  activeMembers?: Maybe<Scalars['Int']>;
  assistantGovernor?: Maybe<Scalars['String']>;
};

export type Props = {
  clubsList: DistrictClub[];
  totalCount: number;
  isLoading: boolean;
  // filterProps: ClubFilterProps;
  showFiltersInContextMenu: boolean;
  contextMenuTitle: string;
};

const ClubsList: React.FC<Props> = ({
  clubsList,
  totalCount,
  isLoading,
  // filterProps,
  showFiltersInContextMenu = false,
  contextMenuTitle,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  const { districtClubsSearchInfo }: any = clubsList;
  return (
    <ResultsList
      headerClassName="px-8 tablet:px-5 tablet:bg-gray-100"
      className="-mx-8 tablet:mx-0"
      summary={
        <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
          {t('district-details.clubs.headline', 'Clubs')} ({totalCount})
        </h2>
      }
      // filters={<Filters {...filterProps} />}
      showFiltersInContextMenu={showFiltersInContextMenu}
      contextMenuTitle={contextMenuTitle}
    >
      <ClubsListHeader />
      {districtClubsSearchInfo?.map((club: DistrictClub) => (
        <ResultsListItem key={club.nfKey}>
          <ClubItem club={club} />
        </ResultsListItem>
      ))}
    </ResultsList>
  );
};

export default ClubsList;
