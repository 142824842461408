import React from 'react';

import classNames from 'classnames';

import Link from '@components/Link';

import { useTranslation } from '@external/react-i18next';

import {
  // ClubType,
  // DistrictClub,
  MembershipStatusFilter,
} from '@typings/operations';

enum ClubType {
  RotaractClub = 'Rotaract Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
  RotaryClub = 'Rotary Club',
  SatelliteClub = 'Satellite Club',
}

export type Maybe<T> = T | null;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};
type DistrictClub = {
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  nfKey: Scalars['String'];
  activeMembers?: Maybe<Scalars['Int']>;
  assistantGovernor?: Maybe<Scalars['String']>;
};

type Props = {
  club: DistrictClub;
};

const ClubItem: React.FC<Props> = ({ club }) => {
  const { t } = useTranslation();

  const {
    nfKey,
    clubName,
    clubType,
    state,
    country,
    activeMembers,
    assistantGovernor,
  } = club;

  const primaryLocation =
    state && country ? [state, country].filter(loc => loc).join(', ') : '';

  const isRotaractors = clubType === ClubType.RotaractClub;
  const isActiveUsersCountDisplayed =
    clubType === ClubType.RotaryClub || isRotaractors;

  return (
    <div
      className="result-row w-full px-8 mb-8 last:mb-0 flex flex-col tablet:items-center tablet:flex-row tablet:justify-between tablet:px-5"
      data-testid="result-row"
    >
      <div className="result-info tablet:w-2/6">
        <div className="result-info__name font-bold">
          {/* <Link
            to={`/domui/club/${id}/details`}
            className="underline"
            noLinkStyles
          > */}
          {`${clubName}(${clubType})`}
          {/* </Link> */}
        </div>
        <div className="result-info__location">{primaryLocation}</div>
        {/* <div className="result-info__location">{`ID: ${riClubId}`}</div> */}
      </div>
      <div className="result-governor tablet:block tablet:w-1/4 ">
        {assistantGovernor && (
          <>
            <span className="desktop:hidden">
              {`${t('assistant-governor', 'Assistant Governor')}: `}
            </span>
            {/* <Link noLinkStyles to={`/profile/${governor.agId}`}> */}
            {assistantGovernor}
            {/* </Link> */}
          </>
        )}
      </div>
      <div
        className={classNames(
          'tablet:w-1/5',
          isActiveUsersCountDisplayed && activeMembers && activeMembers > 0
            ? 'result-memberships'
            : ''
        )}
      >
        {isActiveUsersCountDisplayed &&
          (activeMembers && activeMembers > 0 ? (
            <Link
              to={`/domui/club/${nfKey}/roster?membershipStatus=${
                isRotaractors
                  ? MembershipStatusFilter.Rotaractor
                  : MembershipStatusFilter.Active
              }`}
              className="tablet:font-bold underline"
              noLinkStyles
            >
              {t(
                'district-details.clubs.active-memberships',
                '{{activeMembers}} Active Members',
                {
                  activeMembers,
                }
              )}
            </Link>
          ) : (
            <p className="tablet:font-bold ">
              {t(
                'district-details.clubs.active-memberships',
                '{{activeMembers}} Active Members',
                {
                  activeMembers,
                }
              )}
            </p>
          ))}
        {(clubType === ClubType.SatelliteClub ||
          clubType === ClubType.RotaractSatelliteClub) && (
          <p>
            {t(
              'district-details.clubs.memberships.satellite',
              'Members are shown in Sponsoring club list'
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default ClubItem;
