// Libs
import React from 'react';

// Components
import RowDesktop from './RowDesktop';
import RowMobile from './RowMobile';

// Types
import { MemberRowProps } from './types';

const MemberRow: React.FC<MemberRowProps> = props => {
  return (
    <>
      <RowDesktop {...props} />
      <RowMobile {...props} />
    </>
  );
};

export default MemberRow;
