import React from 'react';

import { Avatar } from '../Avatar';
import Link from '../Link';

import { storePreviousPagePath } from '@utils/storePreviousPagePath';

type Props = {
  name: string;
  path?: string;
  email?: string | null;
  photoUri?: string | null;
  noLinkStyles?: boolean;
  linkClassName?: string;
  dataTestId?: string;
  profilePreviousLinkPath?: string;
};

const MemberPreview: React.FC<Props> = ({
  name,
  path,
  email,
  photoUri,
  noLinkStyles,
  linkClassName,
  dataTestId,
  profilePreviousLinkPath,
}) => {
  return (
    <div
      className="flex items-center cursor-default mb-4"
      data-testid={dataTestId}
    >
      <Avatar
        className="flex justify-center desktop:w-20 mobile:w-20"
        photoUri={photoUri || undefined}
      />
      <div className="w-full">
        {path ? (
          <Link
            to={path}
            noLinkStyles={noLinkStyles}
            className={linkClassName}
            onClick={() => storePreviousPagePath(profilePreviousLinkPath)}
          >
            {name}
          </Link>
        ) : (
          <span className={linkClassName}>{name}</span>
        )}
        <Link className="text-sm" to={`mailto:${email}`}>
          {email}
        </Link>
      </div>
    </div>
  );
};

export default MemberPreview;
