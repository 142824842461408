import React from 'react';

// import { sumBy } from 'lodash';

import BlockWrapper from './BlockWrapper';

import { CLUB_FILTERS } from '@domain/districts';

import { localizedNavigate } from '@utils/localized-navigate';

import { DynamicData } from '@domui-domain/type';
import DetailsChart from '@domui-presenters/web/pages/Districts/Details/DetailsChart/DetailsChart';
import { getClubTypesLabels, getLabelValue } from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';
import { useMobileLayout } from '@hooks/useMobileLayout';

// import { DistrictClubsStatistics } from '@typings/operations';

type Props = {
  clubsStatistics: DynamicData;
  districtId: string;
};

const Clubs: React.FC<Props> = ({ clubsStatistics, districtId }) => {
  const { t } = useTranslation();

  const totalCount = clubsStatistics?.totalCount;

  const statisticDetails = clubsStatistics?.clubsGraphDetails;

  const chartData = statisticDetails.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ clubType, count }: any) => ({
      name: clubType,
      count,
    })
  );

  const { isMobileLayout } = useMobileLayout();

  return (
    <BlockWrapper
      title={t(
        'district-details.clubs-heading',
        'District Clubs ({{totalCount}})',
        {
          totalCount,
        }
      )}
      handleClick={() =>
        localizedNavigate(`/domui/district/${districtId}/clubs`)
      }
    >
      <DetailsChart
        chartData={chartData}
        getLinkHref={(value: string) =>
          `/domui/district/${districtId}/clubs?${CLUB_FILTERS.CLUB_TYPES}=${value}`
        }
        getLinkName={(index = 0, value = '') =>
          `${getLabelValue(getClubTypesLabels(t), value)?.label} (${
            chartData[index].count
          })`
        }
        getLinkClass={(value: string) =>
          `leading-relaxed ${value.includes('Satellite') ? 'w-24' : 'pt-2'}`
        }
        graphWidth={isMobileLayout ? '100%' : '75%'}
        graphHeight={200}
        graphBarWidth={125}
      />
    </BlockWrapper>
  );
};

export default Clubs;
