/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

// import officers from './__tests__/mocks/officers';
import DistrictOfficerRolesList from './DistrictOfficerRolesList';
import DistrictOfficersHeader, {
  LocationStateType,
} from './DistrictOfficersHeader';

import {
  CommitteeAppointment,
  DORoles,
  OFFICERS_ROLES,
  Role,
} from '@domain/districts';

import {
  buildOfficersQueryVars,
  buildRolesQueryVars,
  DistrictContext,
  extractOfficersData,
  get3yrTermRoles,
  getAssignedFuture3yrTermRoleIds,
  getDORoles,
  getFormattedRoles,
} from '@use-cases/districts';
import { useSetContextManagedRYs } from '@use-cases/districts/hooks';

import { useFetchAllDistrictOfficers } from '@repositories/districts';

import { getRotaryYear, isCurrentRY } from '@utils/datetime';

import user from '@backend/modules/user';
import { useAppConfig } from '@domui-hooks/appConfig';
import { useFetchDistrictOfficers } from '@domui-hooks/useFetchDistrictOfficers';
import { getDistrictIdFromPath } from '@domui-use-cases/districts';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useFetchRoles } from '@hooks/useFetchRoles';

import { DistrictOfficer } from '@typings/graphql';

import { roles } from '@mocks/data/metadata';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
}

const DistrictOfficers: React.FC<Props> = ({
  districtId,
  riDistrictId,
  location,
}) => {
  const { user } = useAppConfig();
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const [
    {
      committeeManager: { isManager },
      // eslint-disable-next-line no-empty-pattern
      selectedInfo: {},
    },
    setContext,
  ] = useContext(DistrictContext);
  const term = {
    startDate: '2024',
    endDate: '2025',
  };
  const dominoDistrictId = getDistrictIdFromPath(location?.pathname);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: districtOfficersData,
    loading: districtOfficersLoading,
    error: districtOfficersError,
    fetchDistrictOfficers,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  } = useFetchDistrictOfficers();
  const districtOfficersPayload = {
    page: 1,
    pageSize: 100,
    roles: OFFICERS_ROLES,
    rotaryYear: getRotaryYear(),
    riDistrictId,
  };

  useEffect(() => {
    if (riDistrictId && user?.isLoggedIn && dominoDistrictId) {
      fetchDistrictOfficers(dominoDistrictId, districtOfficersPayload);
    }
  }, [riDistrictId, user?.isLoggedIn]);

  const [roles, setRoles] = useState<Role[]>([]);
  const [officers, setOfficers] = useState<DistrictOfficer[]>([]);
  const [districtGovernors, setDistrictGovernors] = useState<DORoles>([]);
  // const [committeeAppointments, setCommitteeAppointments] = useState<DORoles>(
  //   []
  // );

  // const [
  //   getLeadershipRoles,
  //   { data: rolesData, loading: rolesLoading },
  // ] = useFetchRoles();

  // const [
  //   getAllDistrictOfficers,
  //   { data: officersData, loading: officersLoading },
  // ] = useFetchAllDistrictOfficers();

  // const [
  //   getFuture3yrTermDistrictOfficers,
  //   { data: future3yrTermOfficersData, loading: future3yrTermOfficersLoading },
  // ] = useFetchAllDistrictOfficers();

  // useSetContextManagedRYs(districtId, riDistrictId);

  // useEffect(() => {
  //   if (term && riDistrictId) {
  //     getLeadershipRoles({
  //       variables: buildRolesQueryVars(Number(term.endDate) - 1),
  //     });
  //     // getAllDistrictOfficers({
  //     //   variables: buildOfficersQueryVars(
  //     //     districtId,
  //     //     riDistrictId,
  //     //     Number(term.endDate),
  //     //     true
  //     //   ),
  //     // });
  //   }
  // }, [districtId, term, riDistrictId]);

  // useEffect(() => {
  //   setRoles(getFormattedRoles(rolesData));
  // }, [rolesData]);

  // useEffect(() => {
  //   setOfficers(
  //     extractOfficersData(districtOfficersData?.districtOfficersDetails)
  //   );
  // }, [districtOfficersData]);

  useEffect(() => {
    if (term) {
      const isPastTermSelected = term.endDate < getRotaryYear();

      // const [governors, nonGovernors] = getDORoles(
      //   !isPastTermSelected && isManager.currentTerm,
      //   {
      //     roles,
      //     officers,
      //     year: term.endDate,
      //   }
      // );
      // console.log('governors', governors);
      // setDistrictGovernors(governors);
      //  setCommitteeAppointments(nonGovernors);
    }
  }, [roles, officers, term]);

  // useEffect(() => {
  //   const threeYrTermRoles = get3yrTermRoles(roles);

  //   if (
  //     threeYrTermRoles.length &&
  //     riDistrictId &&
  //     term &&
  //     isCurrentRY(term.endDate) &&
  //     isManager.currentTerm
  //   ) {
  //     getFuture3yrTermDistrictOfficers({
  //       variables: buildOfficersQueryVars(
  //         districtId,
  //         riDistrictId,
  //         Number(term.endDate) + 1,
  //         true,
  //         threeYrTermRoles
  //       ),
  //     });
  //   }
  // }, [roles]);

  // if (
  //   rolesLoading ||
  //   officersLoading ||
  //   future3yrTermOfficersLoading ||
  //   term === null
  // ) {
  //   return <Loading />;
  // }

  if (!districtOfficersData || districtOfficersLoading) {
    return <Loading />;
  }

  const handleSelectYear = (rotaryYear: string) => {
    const year = parseInt(rotaryYear, 10);

    setContext((prevState: CommitteeAppointment) => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: { startDate: String(year - 1), endDate: rotaryYear },
      },
    }));
  };
  const leadershipData = districtOfficersData?.districtOfficersDetails
    .map((officer: { districtLeadership: any }) => officer.districtLeadership)
    .flat();
  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-officers',
          '{{prefix}}District officers {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-6">
        <DistrictOfficersHeader
          districtId={districtId}
          selectedYear={term?.endDate}
          handleYearChanged={handleSelectYear}
          locationState={location?.state as LocationStateType}
        />
        <div className="-mx-8 tablet:mx-0">
          <DistrictOfficerRolesList
            title={t(
              'district-officers.district-governors',
              'District Governors'
            )}
            leadershipData={leadershipData}
            officers={districtOfficersData?.districtOfficersDetails}
          />
          {/* <DistrictOfficerRolesList
            title={t(
              'district-officers.committee-appointments',
              'Committee Appointments'
            )}
            notice={t(
              'district-officers.committee-appointments.required-roles-notice',
              '* Represents the Role that is required by Policy'
            )}
            roles={committeeAppointments}
            assignedFuture3yrTermRoleIds={getAssignedFuture3yrTermRoleIds(
              future3yrTermOfficersData,
              term
            )}
          /> */}
        </div>
      </div>
    </>
  );
};

export default DistrictOfficers;
