import { TFunction } from 'i18next';
import { find } from 'lodash';

import { ModalTextContent } from '@domain/districts/types';

import {
  AVAILABLE_TERMS_MODAL,
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARACT_SATELLITE,
  CLUB_TYPE_ROTARY,
  CLUB_TYPE_SATELLITE,
  DISTRICT_EXECUTIVE_SECRETARY,
  DISTRICT_GOVERNOR,
  DISTRICT_GOVERNOR_ELECT,
  DISTRICT_GOVERNOR_NOMINEE,
  MEMBERSHIP_TYPE_HONORARY,
  MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
  MEMBERSHIP_TYPE_MEMBER,
  MEMBERSHIP_TYPE_ROTARACTOR,
  PRIOR_YEAR_MODAL,
  SPECIAL_REPRESENTATIVE,
} from '@domui-domain/districts';

type Labels = {
  value: string;
  label?: string;
  textContent?: ModalTextContent;
};

export const getLabelValue = (labelsList: Labels[], searchedValue: string) => {
  return find(labelsList, ['value', searchedValue]);
};

export const getOfficersRolesLabels = (t: TFunction) => [
  {
    value: DISTRICT_GOVERNOR,
    label: t('district-details.officers.role-dg', 'District Governor'),
  },
  {
    value: DISTRICT_GOVERNOR_ELECT,
    label: t('district-details.officers.role-dge', 'District Governor Elect'),
  },
  {
    value: DISTRICT_GOVERNOR_NOMINEE,
    label: t('district-details.officers.role-dgn', 'District Governor Nominee'),
  },
  {
    value: DISTRICT_EXECUTIVE_SECRETARY,
    label: t(
      'district-details.officers.role-secretary',
      'District Executive Secretary'
    ),
  },
  {
    value: SPECIAL_REPRESENTATIVE,
    label: t('district-details.officers.role-sr', 'Special Representative'),
  },
];

export const getClubTypesLabels = (t: TFunction) => [
  {
    value: CLUB_TYPE_ROTARY,
    label: t('district-details.clubs.type-rotary', 'Rotary'),
  },
  {
    value: CLUB_TYPE_SATELLITE,
    label: t('district-details.clubs.type-satelite', 'Rotary Satellite'),
  },
  {
    value: CLUB_TYPE_ROTARACT,
    label: t('district-details.clubs.type-rotaract', 'Rotaract'),
  },
  {
    value: CLUB_TYPE_ROTARACT_SATELLITE,
    label: t(
      'district-details.clubs.type-rotaractsatellite',
      'Rotaract Satellite'
    ),
  },
];

export const getMembershipLabels = (t: TFunction) => [
  {
    value: MEMBERSHIP_TYPE_MEMBER,
    label: t('district-details.members.status-active', 'Active Rotarians'),
  },
  {
    value: MEMBERSHIP_TYPE_HONORARY,
    label: t('district-details.members.status-honorary', 'Honorary Rotarians'),
  },
  {
    value: MEMBERSHIP_TYPE_ROTARACTOR,
    label: t('district-details.members.status-rotaractors', 'Rotaractors'),
  },
  {
    value: MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
    label: t(
      'district-details.members.status-honorary-rotaractor',
      'Honorary Rotaractors'
    ),
  },
];

export const getClubAssignmentsModalLabels = (t: TFunction) => [
  {
    value: PRIOR_YEAR_MODAL,
    textContent: {
      content: t(
        'global.modals.prior-year.label',
        'Confirm prior year checkbox'
      ),
      title: t('global.modals.prior-year.title', 'Confirm prior year'),
      text: t(
        'global.modals.prior-year.subtitle',
        'Are you sure you want to select prior year group?'
      ),
      saveBtn: t('global.modals.prior-year.save', 'Apply Prior Year Group'),
      continueBtn: t(
        'global.modals.prior-year.skip-saving',
        'Continue without checking'
      ),
    },
  },
  {
    value: AVAILABLE_TERMS_MODAL,
    textContent: {
      content: t(
        'global.modals.available-terms.label',
        'Confirm available term select'
      ),
      title: t('global.modals.available-terms.title', 'Confirm term change'),
      text: t(
        'global.modals.available-terms.subtitle',
        'Are you sure you want to change term?'
      ),
      saveBtn: t('global.modals.available-terms.save', 'Apply selected term'),
      continueBtn: t(
        'global.modals.available-terms.skip-saving',
        'Continue without selecting'
      ),
    },
  },
];
