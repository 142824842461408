import { getClubDistrictFromLocal } from '@domui-sitecore/utilities/helper-functions';

// Types
import { OptionTypeBase } from '@external/react-select';

type RotaryYearProps = {
  endDate: string;
  isCurrentRotaryYear: boolean;
  rotaryFullYear: string;
  rotaryYear: number;
  startDate: string;
};

export default ({
  useRYEndForValue = true,
}: Record<string, boolean> = {}): OptionTypeBase[] => {
  const { rotaryYear } = getClubDistrictFromLocal();
  // eslint-disable-next-line array-callback-return, consistent-return
  return rotaryYear.map((value: RotaryYearProps) => ({
    value: String(
      value.isCurrentRotaryYear === useRYEndForValue
        ? value.rotaryYear
        : value.rotaryYear
    ),
    label:
      value.isCurrentRotaryYear === useRYEndForValue
        ? value.rotaryFullYear
        : value.rotaryFullYear,
  }));
};
