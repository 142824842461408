import { useState } from 'react';

import { isEqual } from 'lodash';

import { SearchFormValues } from '@domui-components/Formik/SearchMembersForm/types';

enum MembershipStatusFilter {
  Active = 'Active',
  Deceased = 'Deceased',
  Honorary = 'Honorary',
  HonoraryRotaractor = 'Honorary_Rotaractor',
  Rotaractor = 'Rotaractor',
  Terminated = 'Terminated',
}

interface Option {
  id: string;
  value: string;
  label: string;
}

enum CLUB_ROSTER_FILTERS {
  MEMBER_ROLES = 'roles',
  MEMBERSHIP_TYPES = 'memberTypes',
}

type Filter = {
  districtRoles: Option[];
  memberTypes: Option[];
  clubs: Option[];
};

type ClubRosterFilter = {
  orgId: string;
  name: string;
  email: string;
  filters: Filter;
};

type MembershipStatusOption = {
  value: MembershipStatusFilter;
  label: string;
  id: string;
};

export const useDistrictMembersFilters = (
  clubId: string,
  memberTypesOption: MembershipStatusOption[]
) => {
  const convertToOptions = (
    statusOptions: MembershipStatusOption[]
  ): Option[] => {
    return statusOptions.map(option => ({
      id: option.id,
      value: option.value,
      label: option.label,
    }));
  };

  const INITIAL_FILTERS: ClubRosterFilter = {
    orgId: clubId,
    name: '',
    email: '',
    filters: {
      districtRoles: [],
      memberTypes: convertToOptions(memberTypesOption),
      clubs: [],
    },
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedValues, setSelectedValues] = useState<Filter>({
    districtRoles: [],
    memberTypes: convertToOptions(memberTypesOption),
    clubs: [],
  });
  const [filterValues, setFilterValues] = useState<ClubRosterFilter>(
    INITIAL_FILTERS
  );

  const handleResetFilters = () => {
    setFilterValues(INITIAL_FILTERS);
    setSelectedValues({
      districtRoles: [],
      memberTypes: [],
      clubs: [],
    });
  };

  const handleSearch = ({ orgId, name, email }: SearchFormValues) => {
    setCurrentPage(1);
    setFilterValues({
      orgId,
      name,
      email,
      filters: {
        districtRoles: [],
        memberTypes: convertToOptions(memberTypesOption),
        clubs: [],
      },
    });
  };

  const handleApplyFilters = (values: Option[], name: CLUB_ROSTER_FILTERS) => {
    const updatedFilters: Filter = {
      districtRoles: selectedValues.districtRoles,
      memberTypes: selectedValues.memberTypes,
      clubs: selectedValues.clubs,
    };

    if (name === CLUB_ROSTER_FILTERS.MEMBER_ROLES) {
      updatedFilters.districtRoles = values;
      setSelectedValues({
        ...selectedValues,
        districtRoles: values,
      });
    } else if (name === CLUB_ROSTER_FILTERS.MEMBERSHIP_TYPES) {
      updatedFilters.memberTypes = values;
      setSelectedValues({
        ...selectedValues,
        memberTypes: values,
      });
    }

    const newFilterValues: ClubRosterFilter = {
      orgId: clubId,
      name: '',
      email: '',
      filters: updatedFilters,
    };

    if (!isEqual(filterValues, newFilterValues)) {
      setCurrentPage(1);
      setFilterValues(newFilterValues);
    }
  };

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return {
    selectedValues,
    setSelectedValues,
    filterValues,
    setFilterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
    handlePagination,
  };
};
