/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
// import { isEqual } from 'lodash';

// import { Option } from '@components/Formik/MultiSelectFilter/types';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { titleTemplateParams } from '@components/Title/util';

import ClubsList from './ClubsList';
import SearchClubsForm from './SearchClubsForm';

import { CLUB_FILTERS, ClubFilter } from '@domain/districts';

import {
  // getClubTypesOptions,
  // getDefaultFilters,
  // getValuesFromOptions,
  getDistrictIdFromPath,
} from '@use-cases/districts';

import { useDistrictClubsSearch } from '@domui-hooks/useDistrictClubsSearch';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
// import { useAppConfig } from '@hooks/appConfig';

import { FilterSearchFormValues as SearchValues } from './SearchClubsForm/types';

export type Maybe<T> = T | null;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

type DistrictClub = {
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  nfKey: Scalars['String'];
  activeMembers?: Maybe<Scalars['Int']>;
  assistantGovernor?: Maybe<Scalars['String']>;
};

interface Props extends RouteComponentProps {
  // orgId: string;
  // riDistrictId?: number | null;
}

const PAGE_SIZE = 10;

const INITIAL_FILTERS = {
  clubTypes: [],
};
const INITIAL_SEARCH = {
  name: '',
  location: '',
};

const DistrictClubs: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const orgId = getDistrictIdFromPath(location?.pathname);
  // const { user } = useAppConfig();

  // const multiSelectOptions = {
  //   clubTypes: getClubTypesOptions(t),
  // };

  // Set predefined filter value to default object
  // const defaultFilters = getDefaultFilters(
  //   INITIAL_FILTERS,
  //   multiSelectOptions.clubTypes
  // ) as ClubFilter;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<ClubFilter>(INITIAL_FILTERS);
  const [search, setSearch] = useState<SearchValues>(INITIAL_SEARCH);

  // const isSearchActive = Boolean(
  //   search.name || search.location // || filter.clubTypes.length
  // );

  const {
    data: districtClubSearchData,
    loading: isSearching,
    // error,
    districtClubsSearch,
  } = useDistrictClubsSearch();

  useEffect(() => {
    if (orgId) {
      districtClubsSearch(currentPage, PAGE_SIZE, {
        orgId,
        filters: {
          name: search?.name,
          location: search?.location,
          clubTypes: filter?.clubTypes,
        },
      });
    }
  }, [currentPage, PAGE_SIZE, search]);

  const filteredList = districtClubSearchData as DistrictClub[];
  const filteredCount = districtClubSearchData?.totalCount;

  if (isSearching || !districtClubSearchData) {
    return <Loading />;
  }

  const handleSelectPage = (event: React.SyntheticEvent, page: number) => {
    event.preventDefault();

    setCurrentPage(page);
  };

  // const filteredCount = isSearchActive
  //   ? foundClubs?.districtClubs.totalCount || 0
  //   : data?.districtClubs?.totalCount || 0;

  // const isPaginationActive = filteredList.length > 0 && !isSearching;

  // const applyFilter = (values: Option[], name: CLUB_FILTERS) => {
  //   const updatedFilters = {
  //     ...filter,
  //     [name]: values,
  //   };

  //   if (!isEqual(filter, updatedFilters)) {
  //     setCurrentPage(1);
  //     setFilter({
  //       ...filter,
  //       [name]: values,
  //     });
  //   }
  // };

  // const resetFilter = (filterName?: string): void => {
  //   setCurrentPage(1);

  //   if (
  //     filterName &&
  //     Object.values(CLUB_FILTERS).includes(filterName as CLUB_FILTERS)
  //   ) {
  //     setFilter({ ...filter, [filterName]: [] });
  //   } else {
  //     setFilter(INITIAL_FILTERS);
  //   }
  // };

  const handleClubSearch = (values: SearchValues) => {
    setCurrentPage(1);
    setSearch(values);
    // resetFilter();
  };

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-clubs',
          '{{prefix}} District clubs {{suffix}}',
          {
            prefix,
            suffix,
          }
        )}
      />
      <SearchClubsForm
        loading={isSearching}
        onSubmit={handleClubSearch}
        initialValues={{
          ...search,
        }}
      />
      <ClubsList
        clubsList={filteredList}
        totalCount={filteredCount}
        isLoading={isSearching}
        // districtAGs={AGs?.ags as DistrictAG[]}
        // filterProps={{
        //   initialFilterValues: { ...filter },
        //   multiSelectOptions: { ...multiSelectOptions },
        //   resetFilter,
        //   applyFilter,
        // }}
        showFiltersInContextMenu
        contextMenuTitle={t('district.filters.filter-clubs', 'Filter Clubs')}
      />
      {/* {isPaginationActive && ( */}
      <Pagination
        pageSize={PAGE_SIZE}
        page={currentPage}
        totalCount={filteredCount}
        pageHandler={handleSelectPage}
      />
      {/* )} */}
    </>
  );
};

export default DistrictClubs;
