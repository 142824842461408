/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React from 'react';

// import moment from 'moment';
// import { v4 as uuid } from 'uuid';

// Components
// import { Icon } from '@components/Icon';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import AGMemberItem from './AGMemberItem';

// import { buildAGsPageUrl, DistrictContext } from '@use-cases/districts';

// import { getRotaryYear } from '@utils/datetime';
// import { localizedNavigate } from '@utils/localized-navigate';

// Utils
import {
  // ASSISTANT_GOVERNOR,
  // ASSISTANT_GOVERNOR_ROLE_ID,
  DistrictAG,
  // OperationType,
} from '@domui-domain/districts';
// import { DistrictContext } from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';

// Types

// import { Maybe } from '@typings/graphql';

interface Props {
  districtId: string;
  pageHandler: (event: React.SyntheticEvent, pageNumber: number) => void;
  currentPage: number;
  pageSize: number;
  totalCount?: number;
  districtAGs?: DistrictAG[];
  filters: React.ReactNode;
  // riDistrictId: any;
}

const AGList: React.FC<Props> = ({
  districtId,
  pageHandler,
  currentPage = 1,
  pageSize,
  totalCount = 0,
  districtAGs,
  filters,
  // riDistrictId,
}) => {
  const { t } = useTranslation();
  // const isManager = {
  //   currentTerm: false,
  //   nextTerm: false,
  //   nowTerm: false,
  //   afterNowTerm: false,
  // };

  // const [
  //   {
  //     // districtId,
  //     selectedInfo: { term },
  //     // committeeManager: { isManager },
  //   },
  //   // setContext,
  // ] = useContext(DistrictContext);
  // const term = { startDate: '2024', endDate: '2025' };
  // const currentRY = getRotaryYear();

  // const canAddNewAG =
  //   isManager.currentTerm &&
  //   moment(term?.endDate || '').isSameOrAfter(currentRY);

  // const handleAddNewAG = () => {
  //   setContext(prevState => ({
  //     ...prevState,
  //     // step: 1,
  //     selectedInfo: {
  //       ...prevState.selectedInfo,
  //       // role: {
  //       //   name: ASSISTANT_GOVERNOR,
  //       //   id: ASSISTANT_GOVERNOR_ROLE_ID,
  //       // },
  //     },
  //     // operationType: OperationType.ASSIGN_TO_AG,
  //   }));
  //   localizedNavigate(
  //     `/domui/district/${districtId}/members/manage-assistant-governors/${uuid()}`,
  //     {
  //       state: {
  //         backUrl: buildAGsPageUrl(districtId),
  //       },
  //     }
  //   );
  // };

  return (
    <>
      <ResultsList
        filters={filters}
        hideMobileFilter
        className="district-members-result desktop:mx-0 -mx-8"
        headerClassName="desktop:bg-gray-100 desktop:px-5 px-8"
        summary={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('district-management.ags', 'Assistant Governors')} ({totalCount})
          </h2>
        }
      >
        {/* {canAddNewAG ? (
          <ResultsListItem
            key="add-new-ag"
            className="items-center cursor-pointer"
            spacingY={6}
            onClick={handleAddNewAG}
          >
            <div className="flex-1 ml-5 text-lg leading-lg-heading font-normal text-bright-blue-600">
              {t('add-new-ag', 'Add a New Assistant Governor')}
            </div>
            <button type="button" className="focus:shadow-none">
              <span aria-hidden="true" className="text-center">
                <Icon className="w-16" name="circles-lg/add" size="40" />
              </span>
            </button>
          </ResultsListItem>
        ) : null} */}
        {!!districtAGs?.length &&
          districtAGs?.map(item => (
            <ResultsListItem key={item.id}>
              <AGMemberItem
                agItem={item}
                // riDistrictId={riDistrictId}
                districtId={districtId}
                // managerInfo={{
                //   endTermDate: term?.endDate || '',
                //   isCurrentYearManager: isManager.currentTerm,
                //   isManagerNow: isManager.nowTerm,
                //   isManagerAfterNow: isManager.afterNowTerm,
                // }}
              />
            </ResultsListItem>
          ))}
      </ResultsList>
      {totalCount > 0 && (
        <Pagination
          pageSize={pageSize}
          page={currentPage}
          totalCount={totalCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};

export default AGList;
