import React from 'react';

import BlockWrapper from './BlockWrapper';

import { localizedNavigate } from '@utils/localized-navigate';

import MemberPreview from '@domui-components/MemberPreview';
import { OFFICERS_ROLES } from '@domui-domain/districts';
import {
  buildOfficersPageUrl,
  getActiveDO,
  getLabelValue,
  getOfficersRolesLabels,
  sortItemsByRequiredOrder,
} from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  officers: any[];
  districtId: string;
  profilePreviousLinkPath?: string;
};

const Officers: React.FC<Props> = ({
  officers,
  districtId,
  profilePreviousLinkPath,
}) => {
  const { t } = useTranslation();
  const districtLeadership = officers.flatMap(
    ({ districtLeadership }) => districtLeadership
  );

  const getName = (
    prefix: string,
    firstName: string,
    middleName: string,
    lastName: string,
    suffix: string
  ) =>
    [prefix, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');

  const filteredArray = officers.filter(individual =>
    individual.districtLeadership.some((leadership: { roleId: string }) =>
      sortItemsByRequiredOrder(
        getActiveDO(districtLeadership),
        OFFICERS_ROLES,
        'role'
      ).some((role: { roleId: string }) => role.roleId === leadership.roleId)
    )
  );
  return (
    <BlockWrapper
      title={t('district-details.officers-heading', 'District Officers')}
      handleClick={() => {
        localizedNavigate(buildOfficersPageUrl(districtId), {
          state: {
            isFromDetailsPage: true,
          },
        });
      }}
    >
      {filteredArray.map(
        ({
          id,
          prefix,
          firstName,
          middleName,
          lastName,
          suffix,
          districtLeadership,
          primaryEmail,
          photoUri,
        }) => {
          return (
            <div key={id}>
              <div className="text-2xs text-gray-600 font-bold mb-2">
                {
                  getLabelValue(
                    getOfficersRolesLabels(t),
                    districtLeadership?.[0].role
                  )?.label
                }
              </div>
              <MemberPreview
                name={getName(prefix, firstName, middleName, lastName, suffix)}
                path={`/profile/${id}`}
                photoUri={photoUri}
                email={primaryEmail?.address}
                noLinkStyles
                linkClassName="underline text-xs text-gray-600 block"
                key={id}
                dataTestId="officer-item"
                profilePreviousLinkPath={profilePreviousLinkPath}
              />
            </div>
          );
        }
      )}
    </BlockWrapper>
  );
};

export default Officers;
